.main-button {
  padding: 10px;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  font-family: 'Work Sans';
  cursor: pointer;
  border-radius: 8px;
  outline: none;
  border: none;
  color: white;
  background: #F05A25;
}

p.error {
  color: #F05A25;
  font-weight: bold;
  font-size: 0.9em;
}

.back-button {
  font-size: 18px;
  font-weight: bold;
  font-family: 'Work Sans';
  cursor: pointer;
  outline: none;
  border: none;
  color: #555;
  background: none;
}

.question-page-action-buttons-wrap {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}


.triage-page {
  display: grid;
  grid-template-columns: [header-left] 1fr [header-right] auto;
  grid-template-rows: [header] auto [body] auto [buttons] auto [footer] auto;
  grid-template-areas: 
    "header-left   header-right"
    "body          body        "
    "buttons       buttons     "
    "footer        footer      ";
  column-gap: 20px;
  row-gap: 10px;

  .triage-page-header-wrap {
    grid-area: header-left;
    align-self: start;
    p {
      margin-bottom: 10px;
    }
  }

  .triage-page-image-wrap {
    grid-area: header-right;
    height: 57px;
    overflow: visible;
    img { height: 75px;}
    align-self: end;
  }
  .triage-page-body-wrap {
    grid-area: body;
  }
  .question-page-action-buttons-wrap {
    grid-area: buttons;
  }
  .triage-page-footer-wrap{
    grid-area: footer;
  }
}

.triage-question {
  ul {
    list-style: none;
    li {
      cursor: pointer;
      background: white;
      border: 1px solid #C9C9C9;
      padding: 10px 20px;
      border-radius: 8px;
      margin-bottom: 12px;
      font-size: 18px;
      display: grid;
      grid-template-columns: [input] auto [label] 1fr;
      column-gap: 20px;
      align-items: center;
      position: relative;
      .input-wrap{
        cursor: pointer;
        grid-area: input;
        display: grid;
        align-items: center;
        input[type='checkbox'], input[type='radio']{
          width: 13px;
          height: 13px;
        }
      }
      .label-wrap{
        cursor: pointer;
        grid-area: label;
      }
      button.cover-background {
        position: absolute;
        display: block;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        background: none;
        border: none;
        outline: none;
        cursor: pointer;
      }
    }
  }
}

.question-topics {
  
}

.value-proposition-summary-page {

  .triage-page-body-wrap {
    background: white;
    border: 1px solid #C9C9C9;
    border-radius: 3px;
    padding: 25px 15px;

    ul {
      list-style: none;

      li {
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0px;
        }
        display: grid;
        grid-template-columns: [tick] auto [body] 1fr;
        grid-template-areas: "tick body";
        column-gap: 10px;
        .value-proposition-tick-wrap {
          grid-area: tick;
        }
        .value-proposition-text-wrap {
          grid-area: body;
          h2 {
            font-size: 18px;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}

.ask-contact-details-page{
  .ask-contact-details {
    & > div.input-wrap {
      margin-bottom: 15px;
    }
    .input-label-text {
      font-weight: bold;
      font-size: 15px;
      margin-bottom: 5px;
    }
    .method-options-wrap{
      ul {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        @media ( max-width: 500px ){
          gap: 10px;
        }
        li {
          margin-bottom: 0px;
        }
      }
    }
  }
  .triage-page-footer-wrap {
    margin-top: 20px;
    h1 {
      font-size: 17px;
      margin-bottom: 10px;
      display: flex;
      align-content: center;
      justify-content: center;
      svg {
        margin-right: 10px;
      }
    }
    p {
      font-size: 13px;
      color: #555;
      text-align: center;
    }
  }
}

.success-page {
  padding: 30px 20px;
  border: 1px solid #C9C9C9;
  border-radius: 3px;

  h1 {
    font-size: 25px;
    margin-bottom: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      margin-right: 15px;
    }
  }
  p {
    margin-bottom: 20px;
    text-align: center;
  }

  ul.resource-list {
    list-style: none;
    margin: 20px 0px 20px;
    &:last-child { margin-bottom: 0px; }
    li {
      display: grid;
      grid-template-columns: [image] auto [text] 1fr;
      grid-template-areas: "image text";
      column-gap: 20px;

      padding: 10px;
      border: 1px solid #C9C9C9;
      border-radius: 8px;
      margin-bottom: 10px;
      position: relative;

      & > a {
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
      }
      
      & > div:first-child {
        grid-area: image;
        img {
          width: 30px;
        }
      }

      & > div:nth-child(2){
        h2 { 
          font-size: 15px; 
          margin-bottom: 5px;
        }
        p { 
          font-size: 13px; 
          text-align: left;
          margin-bottom: 0px;
        }
      }
    }
  }
}