@import './animations.scss';

* {
  margin: 0px;
  padding: 0px;
}

.App {
  display: grid;

  p { 
    font-size: 18px;
  }
  @media (max-width: 500px) {
    p {
      font-size: 15px
    }
  }

  .footer-section {
    display: grid;
    position: relative;

    &, p {
      font-size: 15px;
      color: #555;
    }
    ul {
      list-style: none;
      list-style-position: inside;
      padding: 0px;
      margin: 0px;
      display: flex;
      gap: 40px;
    }
    
    @media(max-width: 500px){
      & {
        padding: 30px 20px 50px;
      }
    }
    @media(min-width: 501px){
      & {
        max-width: 500px;
        padding: 30px 0px 50px;
        justify-self: center;
      }
    }

    .column {
      @media(max-width: 500px){
        & {
          background: white;
        }
      }
    }
  }

  .about-us-section {
    display: grid;
    min-height: 100vh;
    position: relative;
    
    p {
      margin-bottom: 30px;
    }
    h1 {
      margin-bottom: 30px;
    }
    @media(max-width: 500px){
      & {
        padding: 30px 20px 50px;
        background: #F05A25;
      }
    }
    @media(min-width: 501px){
      & {
        padding: 30px 20px 50px;
        max-width: 500px;
        padding: 30px 0px 50px;
        justify-self: center;
      }
    }
  }
  .column {
    display: grid;
    justify-self: center;
    align-self: center;
    // padding: 40px 60px;
    background: white;
    margin-top: 20px;

    & > h1 { font-size: 32px; }

    .ask-anything {
      // text-align: center;
      color: #F05A25;

      @media(max-width: 500px){
        & {
          color: black;
        }
      }

    }

    @media(max-width: 500px){
      & {
        padding: 30px 20px 50px;
        background: #F05A25;
        color: white;
        p.small-text{ color: white; }
      }
    }
  }

  #about-us-header {
    text-align: right;
    margin-bottom: 50px;
    a {
      text-decoration: none;
      color: black;
      font-weight: bold;
    }
  }

  p.small-text {
    font-size: 13px;
    color: #555;
    margin: 5px 0px;
  }

  .contact-us-section {
    display: grid;
    padding: 0px 0px 50px;

    @media(max-width: 500px){
      & {
        padding: 0px;
        border-bottom: 4px solid white;
      }
    }

    @media(min-width: 501px){
      & {
        min-height: 100vh;
        background: #f5926f;
      }
    }
  
    .contact-us-card {
      display: grid;
      justify-self: center;
      max-width: 500px;
      padding: 40px 60px;
      border: 1px solid #DEDEDE;
      border-radius: 8px;
      background: white;
      box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.1);
      row-gap: 30px;
      position: relative;

      @media(max-width: 500px){
        & {
          border: 0px;
          border-radius: 0px;
          box-shadow: none;
          padding: 20px 20px 80px;
        }
      }

      @media(min-width: 501px){
        & {
          align-self: center;
        }
      }

      .header-wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        svg {
          height: 34px;
          width: 113px;

          @media (max-width: 500px){
            height: 31px;
            width: 98px;
          }
        }
        a {
          text-decoration: none;
          color: black;
          font-weight: bold;
        }
      }


      .hero-section {
        margin: 0px auto 0px;
        width: 90%;
        min-width: 300px;

        h2 {
          font-weight: bold;
          font-size: 25px;
          line-height: 28px;
          margin-bottom: 30px;
          text-align: center;
        }
        p {
          text-align: center;
        }

        @media(max-width: 500px){
          h2 {
            font-size: 22px;
            line-height: 24px;
          }
        }
      }

      div.name-and-email-wrap {
        margin-bottom: 20px;
        div.name-and-email-inputs-wrap {
          display: flex;
          align-items: baseline;
          justify-content: space-between;
          gap: 20px;
          margin-bottom:5px;

          @media(max-width: 500px){
            & {
              display: block;
            }
          }

          & > div {
            flex-grow: 1;
          }
        }
        p.small-text{ margin: 0px;}
      }

      h2 {
        font-family: 'Work Sans';
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 8px;
        @media (max-width: 500px){
          font-size: 15px;
        }
      }


      p.input-wrap {
        margin-top:5px;
        margin-bottom: 5px;
      }

      input, textarea {
        padding: 10px;
        border-radius: 8px;
        border: 1px solid #C9C9C9;
        width: calc(100% - 20px);
        font-family: 'Work Sans';
        font-size: 18px;
        @media (max-width: 500px){
          font-size: 15px;
        }
      }

      textarea {
        height: 100px;
        resize: none;
      }

      ul.errors-list {
        list-style: none;
        color: #F05A25;
        font-weight: bold;
        padding: 0px;
        margin: 0px;
      }

      div.sending-status {
        color: #F05A25;
        font-weight: bold;
        text-align: center;

        &[data-status="success"]{
          color: black;
        }
      }


      div.form-section-submit {
        button {
          padding: 10px;
          text-align: center;
          font-size: 18px;
          font-weight: bold;
          font-family: 'Work Sans';
          cursor: pointer;
          border-radius: 8px;
          outline: none;
          border: none;
          width: 100%;
          color: white;
          background: #F05A25;
        }
      }

      .beta-footer {
        text-align: center;
        margin: 10px auto 0px;
      }

      .progress-bar {
        position: absolute;
        // Adjust for thickness of border
        bottom: -1px;
        left: -1px;
        height: 10px;
        background: #F05A25;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 3px;
        border-top-right-radius: 3px;
        border: 1px solid #F05A25;
        transition: width 1s ease-in-out;

        &[data-proportion="100"]{
          border-top-right-radius: 0px;
          border-bottom-right-radius: 8px;
        }

        @media(max-width: 500px){
          & {
            border-top-right-radius: 0px;
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
          }
          &[data-proportion="100"]{
            border-bottom-right-radius: 0px;
          }
        }
      }
    }
  }
}

