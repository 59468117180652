
/* Mad props to https://loading.io/css/ for the spinner */
.spinner {
  display: inline-block;
  position: relative;
  width: 200px;
  height: 80px;

  .dot-wrap {
    width: 80px; height: 80px;
    position: relative;
    margin: 0px auto;

    div {
      position: absolute;
      top: 33px;
      width: 13px;
      height: 13px;
      border-radius: 50%;
      background: white;
      animation-timing-function: cubic-bezier(0, 1, 1, 0);
      
      &:nth-child(1) {
        left: 10%;
        animation: spinner1 0.6s infinite;
      }
      &:nth-child(2) {
        left: 10%;
        animation: spinner2 0.6s infinite;
      }
      &:nth-child(3) {
        left: 40%;
        animation: spinner2 0.6s infinite;
      }
      &:nth-child(4) {
        left: 70%;
        animation: spinner3 0.6s infinite;
      }

    }

  }

  &.inline-spinner {
    height: 13px; width: 30px;

    .dot-wrap {
      width: 100%; height: 100%;

      div {
        top: 4px;
        height: 5px;
        width: 5px;

        &:nth-child(2),
        &:nth-child(3) {
          animation: spinner2Inline 0.6s infinite;
        }
      }
    }
    
  }

}

@keyframes spinner1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes spinner3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes spinner2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
@keyframes spinner2Inline {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(9px, 0);
  }
}
